<!-- 客群管理 -->
<template>
  <div class="holder">
    <section class="part1">
      <div class="w">
        <div class="text_box" id="textBox">
          <h2>数字化金融直播平台，赋能营销，让业务自然增长</h2>
          <p>
            基于金融直播专业性、合规性、实时性的特质，灯云直播为企业提供有效的智能<br />
            投资分析工具、丰富的营销素材、海量直播背景、实时热点资讯和精准<br />
            化的营销策略！
          </p>
          <button @click="openLogin">
            {{ is_login ? "立即开始" : "申请试用" }}
            <img src="@/assets/images/home/right_arrow.svg" alt="" />
          </button>
        </div>
      </div>
    </section>

    <section class="part2">
      <div class="block">
        <div class="w" ref="analyzeVideoBox">
          <h4>智能分析工具</h4>
          <div class="desc">
            覆盖300+金融策略工具，全方位多维度智能诊断，投资决策点一目了然；构建上百种技术分析指标，毫秒级低延迟直播技术加持，实时解盘无压力线下服务线上化，促进财富管理向“云投教”服务转型
          </div>
          <div class="desc_video_box">
            <video
              ref="analyzeVideo"
              class="desc_video"
              muted
              src="@/assets/videos/desc.mp4"
            ></video>
          </div>
        </div>
      </div>
      <div class="block" style="background: #f1f7fb">
        <div class="w" ref="swiperBox">
          <h4>金融素材库</h4>
          <div class="desc">
            海量超高清金融视频图片素材，内容营销快人一步；每日聚合100000+金融资讯，智能分析，紧贴热点；智能化金融标签，快速定位目标内容+金融资讯，智能分析，紧贴热点；智能化金融标签，快速定位目标内容
          </div>
          <div class="swipe_box">
            <ul class="swiper_nav">
              <li @click="cutSwipe(0)" :class="swipeIndex == 0 ? 'active' : ''">
                <div class="swiper_nav_title">背景</div>
                <div class="swiper_nav_desc">
                  不同分类的直播虚拟画面，丰富主播场景，提升画面，直播更高级 。
                </div>
              </li>
              <li @click="cutSwipe(1)" :class="swipeIndex == 1 ? 'active' : ''">
                <div class="swiper_nav_title">资讯</div>
                <div class="swiper_nav_desc">
                  每日100000+金融热点资讯，智能分析，快速搜索并定位目标内容，直播更专业
                  。
                </div>
              </li>
              <li @click="cutSwipe(2)" :class="swipeIndex == 2 ? 'active' : ''">
                <div class="swiper_nav_title">组件</div>
                <div class="swiper_nav_desc">
                  个性化画面组件添加，包括直播提醒、直播LOGO、直播信息、直播时间、求关注等
                  。
                </div>
              </li>
              <li @click="cutSwipe(3)" :class="swipeIndex == 3 ? 'active' : ''">
                <div class="swiper_nav_title">数图</div>
                <div class="swiper_nav_desc">
                  可视化图表，包含宏观、行情、个股、市场、行业等类别，以最新数据对接各类图表展示
                  。
                </div>
              </li>
            </ul>
            <div class="swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img src="@/assets/images/home/swipe1.png" alt="" />
                </div>
                <div class="swiper-slide">
                  <img src="@/assets/images/home/swipe2.png" alt="" />
                </div>
                <div class="swiper-slide">
                  <img src="@/assets/images/home/swipe3.png" alt="" />
                </div>
                <div class="swiper-slide">
                  <img src="@/assets/images/home/swipe4.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="w">
          <h4>数字人直播</h4>
          <div class="desc">
            7*24小时全天候直播，自动化直播模板服务虚拟形象设计，仿真、Q版、卡通多场景支持开放服务接口，企业已有主播形象入驻弹幕语义识别，粉丝互动智能问答
          </div>
          <div>
            <img src="@/assets/images/home/virtual_live.png" alt="" />
          </div>
        </div>
      </div>
      <div class="block" style="background: #f1f7fb">
        <div class="w">
          <h4>公域聚合推流 私域直播引流</h4>
          <div class="desc">
            1台电脑+1个主播实现多平台同步直播，全方位曝光促进营收转化。支持直播地址嵌入企业自有APP、官
            网、公众号等私有平台，布局企业自有流量阵地。
          </div>
          <div>
            <img src="@/assets/images/home/flow_live.png" alt="" />
          </div>
        </div>
      </div>
      <div class="block">
        <div class="w">
          <h4>安全、合规、稳定</h4>
          <div class="desc">
            灯云为金融机构提供安全、稳定、合规的直播环境，对视频直播中的画面、声音、文字进行全方位分析过滤，实时检测直播违规信息
          </div>
          <ul class="discern">
            <li>
              <div class="icon">
                <img src="@/assets/images/home/discern1.png" alt="" />
              </div>
              <h6>图片识别</h6>
              <div class="discern_content">・涉政・暴恐・色情・广告</div>
            </li>
            <li>
              <div class="icon">
                <img src="@/assets/images/home/discern2.png" alt="" />
              </div>
              <h6>音频识别</h6>
              <div class="discern_content">
                ・涉政・色情 ・广告 ・娇喘 <br />・ 声纹 ・标签
              </div>
            </li>
            <li>
              <div class="icon">
                <img src="@/assets/images/home/discern3.png" alt="" />
              </div>
              <h6>视频识别</h6>
              <div class="discern_content">
                ・涉政・色情・广告・水印<br />・暴恐
              </div>
            </li>
            <li>
              <div class="icon">
                <img src="@/assets/images/home/discern4.png" alt="" />
              </div>
              <h6>文本识别</h6>
              <div class="discern_content">
                ・涉政・违禁・色情・辱骂<br />・广告
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="block" style="background: #f1f7fb">
        <div class="w">
          <h4>数据洞察 精准营销</h4>
          <div class="desc">搭建数字化决策平台，赋能精准营销</div>
          <div class="marketing">
            <img src="@/assets/images/home/market.png" alt="" />
          </div>
        </div>
      </div>
    </section>
    <TrialApply v-if="trial_visible" @closeTrialApply="closeTrialApply" />
    <Login @openTrialApply="openTrialApply" />
  </div>
</template>

<script>
import "swiper/css/swiper.min.css";
import Swiper from "swiper";
import TrialApply from "../components/TrialApply/index.vue";
import ScrollReveal from "scrollreveal";
import Login from "../components/Login/index.vue";
import { getCookie, debounce, setRem } from "@/utils/utils.js";
export default {
  data() {
    return {
      trial_visible: false,
      scrollReveal: ScrollReveal(),
      is_login: false,
      mySwiper: null,
      swipeIndex: 0,
      playVideo: true,
    };
  },
  components: {
    TrialApply,
    Login,
  },
  created() {
    if (getCookie("token")) {
      this.is_login = true;
    } else {
      this.is_login = false;
    }
  },
  methods: {
    cutSwipe(index) {
      this.mySwiper.slideTo(index, 1000, false);
      this.swipeIndex = index;
    },

    scrollmagic({ trigger, ele, duration, offset = 0, css, callback }) {
      const scene = this.$scrollmagic.scene({
        triggerElement: trigger,
        triggerHook: 0.8,
        duration: duration,
        offset: offset,
      });
      scene
        .on("end", function (e) {
          callback && callback(e);
        })
        .setTween(
          TweenMax.to(ele, 0.5, {
            css: css,
          })
        );
      this.$scrollmagic.addScene(scene);
    },
    // 控制申请试用窗口
    openTrialApply() {
      this.trial_visible = true;
    },
    closeTrialApply() {
      this.trial_visible = false;
    },
    // 打开登录弹框
    openLogin() {
      if (!this.is_login) {
        this.$bus.$emit("openLoginDialog");
      } else {
        this.$router.push({ path: "/formal" });
      }
    },
    handleScroll() {
      if (this.playVideo) {
        console.log(1);
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let h1 = this.$refs.analyzeVideoBox.offsetTop;
        let h2 = this.$refs.analyzeVideoBox.clientHeight;
        if (scrollTop + 400 > h1 && scrollTop + 500 < h1 + h2) {
          // console.log("播放视频");
          this.$refs.analyzeVideo.play();
        }
      }
    },

    scrollFunc() {
      debounce(this.handleScroll());
    },

    loadSwiper() {
      const that = this;
      var mySwiper = new Swiper(".swiper", {
        loop: false,
        effect: "fade",
        lazyLoading: true, //?????
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        on: {
          slideChangeTransitionEnd: function () {
            // alert(this.activeIndex); //切换结束时，告诉我现在是第几个slide
            that.swipeIndex = this.activeIndex;
          },
        },
      });

      this.mySwiper = mySwiper;
    },
  },
  mounted() {
    setRem(1920)
    this.loadSwiper();
    const that = this;
    this.$nextTick(() => {
      window.addEventListener(
        "scroll",
        debounce(this.handleScroll.bind(that)),
        true
      );
    });
  },
  destroyed() {
    this.playVideo = false;
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
